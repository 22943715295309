export const realStackNames = {
  t60dev: "Three60 Digital Development",
  t60prod: "Three60 Digital Production",
  varenergidev: "Vår Energi Development",
  varenergitest: "Vår Energi Test",
  varenergiprod: "Vår Energi Production",
} as const;

export type StackName = keyof typeof realStackNames;

export const stackNames = Object.keys(realStackNames) as StackName[];

export const containerAppsEndpoints = {
  varenergidev: "wittystone-d90a5db2.westeurope.azurecontainerapps.io",
  varenergitest: "salmondune-6b31fd4b.westeurope.azurecontainerapps.io",
  varenergiprod: "thankfulwater-3423c6d8.westeurope.azurecontainerapps.io",
  t60dev: "lemonpebble-f541c1fe.westeurope.azurecontainerapps.io",
  t60prod: "redcliff-9d78d0f6.westeurope.azurecontainerapps.io",
} as const;

/**
 * Function responsible for returning the function endpoints
 * @param name The name of the python function
 * @param stacks The stacks that this function is available
 * @returns The function endpoints
 */
export const functionEndpoints = <T extends StackName>(
  name: string,
  stacks: T[]
): Record<T, string> => {
  return Object.fromEntries(
    stacks.map((stack) => [
      stack,
      `https://${name}-fun.${containerAppsEndpoints[stack]}/`,
    ])
  ) as Record<T, string>;
};
