import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  getCurrentRelease,
  getCurrentStackName,
} from "library-frontend-utils/infra";
import feedbackIntegration from "library-frontend-utils/sentry/helpers/feedbackIntegration";

const environment = getCurrentStackName();

Sentry.init({
  dsn: "https://44383c9720b2575073cb14843299ca3c@o4505051041628160.ingest.us.sentry.io/4507944475099136",
  tunnel:
    environment === "local"
      ? "http://localhost:3001/report"
      : "https://apps.t60.io/report",
  enabled: environment !== "local",
  release: getCurrentRelease(),
  environment,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [/.+/],
      maskAllText: false,
      blockAllMedia: false,
    }),
    feedbackIntegration,
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/.+/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
